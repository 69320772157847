import React from "react";

function IconInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      {...props}
    >
      <g id="instagram" opacity="0.905">
        <path
          id="Pfad_198"
          data-name="Pfad 198"
          d="M5.134,0H34.866A5.012,5.012,0,0,1,40,5.134V34.866A5.012,5.012,0,0,1,34.866,40H5.134A5.012,5.012,0,0,1,0,34.866V5.134A5.012,5.012,0,0,1,5.134,0Zm24,4.444a1.805,1.805,0,0,0-1.8,1.8v4.31a1.805,1.805,0,0,0,1.8,1.8h4.521a1.806,1.806,0,0,0,1.8-1.8V6.245a1.806,1.806,0,0,0-1.8-1.8Zm6.341,12.472H31.959a11.7,11.7,0,0,1,.513,3.431A12.245,12.245,0,0,1,20.038,32.394,12.244,12.244,0,0,1,7.605,20.347a11.679,11.679,0,0,1,.513-3.431H4.445v16.9a1.6,1.6,0,0,0,1.59,1.59H33.89a1.6,1.6,0,0,0,1.59-1.59v-16.9ZM20.038,12.129A7.912,7.912,0,0,0,12,19.913,7.912,7.912,0,0,0,20.038,27.7a7.912,7.912,0,0,0,8.034-7.784A7.912,7.912,0,0,0,20.038,12.129Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default IconInstagram;
