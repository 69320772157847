import React from "react";

function IconYoutube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.766"
      height="40"
      viewBox="0 0 33.766 40"
      {...props}
    >
      <g id="youtube" transform="translate(108.933 1122)" opacity="0.905">
        <g
          id="Gruppe_91"
          data-name="Gruppe 91"
          transform="translate(-108.933 -1122)"
        >
          <path
            id="Pfad_200"
            data-name="Pfad 200"
            d="M40.249,41.111a4.265,4.265,0,0,0-3.65-3.318,113.442,113.442,0,0,0-12.643-.468,113.478,113.478,0,0,0-12.645.468,4.264,4.264,0,0,0-3.648,3.318,36.153,36.153,0,0,0-.6,7.982,36.146,36.146,0,0,0,.588,7.981A4.264,4.264,0,0,0,11.3,60.392a113.449,113.449,0,0,0,12.644.468,113.368,113.368,0,0,0,12.645-.468,4.265,4.265,0,0,0,3.649-3.318,36.174,36.174,0,0,0,.591-7.981A36.2,36.2,0,0,0,40.249,41.111ZM16.7,43.093H14.254V56.079h-2.27V43.093h-2.4V40.966H16.7Zm6.166,12.986H20.822V54.851a3.27,3.27,0,0,1-2.332,1.383,1.252,1.252,0,0,1-1.315-.837A5.747,5.747,0,0,1,17,53.723v-8.9h2.04v8.291a6.354,6.354,0,0,0,.019.794c.049.317.2.477.477.477.409,0,.837-.315,1.29-.955V44.818h2.042V56.079Zm7.752-3.38a9,9,0,0,1-.206,2.269,1.616,1.616,0,0,1-1.63,1.266,2.781,2.781,0,0,1-2.109-1.246V56.08H24.63V40.966h2.04V45.9a2.764,2.764,0,0,1,2.109-1.221,1.614,1.614,0,0,1,1.63,1.27,8.647,8.647,0,0,1,.206,2.264Zm7.7-1.879H34.236v2c0,1.042.341,1.563,1.042,1.563a.877.877,0,0,0,.911-.818,12.839,12.839,0,0,0,.046-1.383h2.082v.3c0,.658-.025,1.111-.044,1.315a2.908,2.908,0,0,1-.476,1.222,2.824,2.824,0,0,1-2.468,1.221,2.922,2.922,0,0,1-2.47-1.153,4.362,4.362,0,0,1-.655-2.648V48.489a4.383,4.383,0,0,1,.631-2.653,2.9,2.9,0,0,1,2.444-1.154,2.834,2.834,0,0,1,2.407,1.154,4.426,4.426,0,0,1,.632,2.653v2.331Z"
            transform="translate(-7.067 -20.86)"
            fill="currentColor"
          />
          <path
            id="Pfad_201"
            data-name="Pfad 201"
            d="M69.681,58.163q-1.023,0-1.024,1.562v1.042H70.7V59.725C70.7,58.684,70.356,58.163,69.681,58.163Z"
            transform="translate(-41.488 -32.506)"
            fill="currentColor"
          />
          <path
            id="Pfad_202"
            data-name="Pfad 202"
            d="M52.523,58.163a1.462,1.462,0,0,0-1.018.5v6.864a1.456,1.456,0,0,0,1.018.5c.588,0,.888-.5.888-1.52V59.7C53.41,58.684,53.111,58.163,52.523,58.163Z"
            transform="translate(-31.902 -32.506)"
            fill="currentColor"
          />
          <path
            id="Pfad_203"
            data-name="Pfad 203"
            d="M54.481,20.357a3.313,3.313,0,0,0,2.356-1.4V20.2H58.9V8.82H56.837v8.7c-.457.646-.89.965-1.3.965-.276,0-.438-.163-.483-.483a4.782,4.782,0,0,1-.025-.8V8.82H52.97v9a5.75,5.75,0,0,0,.183,1.692A1.266,1.266,0,0,0,54.481,20.357Z"
            transform="translate(-32.721 -4.929)"
            fill="currentColor"
          />
          <path
            id="Pfad_204"
            data-name="Pfad 204"
            d="M20.222,9.086v6.185h2.287V9.086L25.259,0H22.947l-1.56,6L19.763,0H17.357c.482,1.416.984,2.838,1.466,4.255A37.619,37.619,0,0,1,20.222,9.086Z"
            transform="translate(-12.818)"
            fill="currentColor"
          />
          <path
            id="Pfad_205"
            data-name="Pfad 205"
            d="M38.359,20.182a2.811,2.811,0,0,0,2.405-1.165,4.452,4.452,0,0,0,.64-2.676V12.356a4.46,4.46,0,0,0-.64-2.681,3.058,3.058,0,0,0-4.807,0,4.423,4.423,0,0,0-.645,2.681v3.985a4.411,4.411,0,0,0,.645,2.676A2.811,2.811,0,0,0,38.359,20.182Zm-.984-8.24c0-1.053.319-1.578.984-1.578s.983.525.983,1.578V16.73c0,1.053-.318,1.58-.983,1.58s-.984-.527-.984-1.58Z"
            transform="translate(-22.853 -4.755)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconYoutube;
