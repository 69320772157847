import React from "react";

function IconTwitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.107"
      height="40"
      viewBox="0 0 49.107 40"
      {...props}
    >
      <g id="twitter" transform="translate(86 1116.947)" opacity="0.902">
        <path
          id="Pfad_199"
          data-name="Pfad 199"
          d="M49.049,13.817a.243.243,0,0,0-.286-.066,19.643,19.643,0,0,1-4.6,1.4A10.209,10.209,0,0,0,47.753,10.1a.244.244,0,0,0-.357-.284,19.689,19.689,0,0,1-6.126,2.364,10.228,10.228,0,0,0-17.388,9.1A28.178,28.178,0,0,1,3.818,10.968a.238.238,0,0,0-.208-.089.243.243,0,0,0-.192.12A10.231,10.231,0,0,0,5.881,24.132a9.767,9.767,0,0,1-3.565-1.153.244.244,0,0,0-.363.208v.131A10.253,10.253,0,0,0,9.136,33.08a9.815,9.815,0,0,1-3.4-.049.244.244,0,0,0-.278.313,10.217,10.217,0,0,0,8.871,7.062A19.641,19.641,0,0,1,2.623,44.223a20.211,20.211,0,0,1-2.35-.138.247.247,0,0,0-.259.159.244.244,0,0,0,.1.288,28.514,28.514,0,0,0,15.423,4.521c17.918,0,28.625-14.555,28.625-28.625q0-.588-.023-1.17a20.379,20.379,0,0,0,4.93-5.148A.244.244,0,0,0,49.049,13.817Z"
          transform="translate(-86 -1126)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default IconTwitter;
