import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Theme from "../Theme";
import ReactGA from "react-ga4";

function CookieConsent() {
  const KEY = "cookie-consent-accepted";

  const [accepted, setAccepted] = useState<string | null>(
    localStorage?.getItem(KEY)
  );

  useEffect(() => {
    if (accepted === "true") {
      ReactGA.initialize("G-3EE820NBRE");
    }
  }, [accepted]);

  return (
    <>
      {localStorage?.getItem(KEY) == null && (
        <Container
          visible={accepted == null}
          onAnimationEnd={(e) => {
            if (accepted) localStorage?.setItem(KEY, "true");
          }}
        >
          <FlexContainer>
            <Consent>
              <Header>Wir respektieren Ihre Privatsphäre</Header>
              <Message>
                Diese Website verwendet Funktionen und Cookies von
                Drittanbietern, um sicherzustellen, dass Sie die bestmögliche
                Erfahrung beim Besuch der Website haben. Durch die Nutzung der
                Website erklären Sie sich mit der Verwendung von Funktionen und
                Cookies Drittanbieter einverstanden.
              </Message>
            </Consent>
            <Button onClick={() => setAccepted("true")}>Ok</Button>
          </FlexContainer>
        </Container>
      )}
    </>
  );
}

export default CookieConsent;

const slideIn = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: .9;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: .9;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  color: white;
  background-color: black;
  opacity: 0.98;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.55);
  z-index: 6;
  animation: ${(p) => (p.visible ? slideIn : slideOut)} 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  opacity: 0.5;

  @media screen and (max-width: 450px) {
    padding: 1rem;
  }
`;

const Button = styled.a`
  font-size: 1rem;
  background-color: yellow;
  padding: 0.7rem 1.7rem;
  border-radius: 1.4rem;
  color: black;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
  text-transform: uppercase;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  text-align: center;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: lighten(${Theme.colors.secondary}, 10%);
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 2rem;
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;
    & > :not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 1rem;
      text-align: center;
    }
  }
`;

const Consent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Header = styled.h1`
  font-size: 1.1rem;
  font-weight: bold;
  padding-bottom: 1rem;
`;

const Message = styled.div`
  font-size: 0.9rem;
  line-height: 1.5rem;
`;
