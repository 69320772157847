import React from "react";
import styled from "styled-components";
import Theme from "../Theme";
import UserEvent from "../analytics/UserEvent";
import { FadeInBottom } from "./animations/FadeInBottom";
import { ReactComponent as HeroImage } from "../assets/hero-image.svg";
import { darken } from "polished";

function Hero() {
  return (
    <HeroSection>
      <PositionedHeroImage />
      <HeroHeader>Ellas Kreativ Studio</HeroHeader>
      <HeroSubHeader>
        Die Vielfalt des Nähens, in liebevoller Handarbeit.
      </HeroSubHeader>
      <HeroDescription>
        Interessiert an einer unverbindlichen Beratung? Dann zögern Sie nicht,
        mich zu kontaktieren! Ich bespreche gerne Ihre individuellen Wünsche und
        helfe Ihnen dabei, Ihre kreativen Träume zu verwirklichen. Rufen Sie
        mich jetzt an oder schreiben Sie mir, um Ihren persönlichen
        Beratungstermin zu vereinbaren.
      </HeroDescription>
      <CallToActionContainer>
        <PrimaryCallToAction
          href="tel:+491786114500"
          onClick={() => UserEvent("Anruf klick in der Hero Section")}
        >
          Jetzt anrufen
        </PrimaryCallToAction>
        <SecondaryCallToAction
          href="https://www.instagram.com/ellaskreativstudio/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => UserEvent("Instagram klick in der Hero Section")}
        >
          Folge mir auf Instagram
        </SecondaryCallToAction>
      </CallToActionContainer>
    </HeroSection>
  );
}

export default Hero;

const HeroSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  min-height: 100vh;
  text-align: left;
  color: ${Theme.colors.dark};
  padding: 7rem 1rem 7rem 1rem;
  box-sizing: border-box;
  width: 100%;

  padding: 8rem 10rem;

  & > :not(:last-child) {
    padding-bottom: 4rem;
  }

  @media screen and (max-width: 800px) {
    align-items: center;
    padding: 8rem 2rem;
    & > :not(:last-child) {
      padding-bottom: 3rem;
    }
  }
`;

const PositionedHeroImage = styled(HeroImage)`
  position: absolute;
  width: 50rem;
  left: calc(100% - 0.76 * 50rem);
  bottom: -5rem;
  z-index: -1;

  @media screen and (max-width: 800px) {
    width: 30rem;
    left: calc(50% - 0.5 * 30rem);
    bottom: -20rem;
    top: auto;
  }
`;

const HeroHeader = styled.h1`
  font-size: 4rem;
  font-family: "barcelonyregular";
  font-weight: 500;

  @media screen and (max-width: 800px) {
    font-size: 2.3rem;
    text-align: center;
  }

  ${FadeInBottom}
`;

const HeroSubHeader = styled.h2`
  max-width: 40vw;
  font-size: 1.2rem;

  ${FadeInBottom}
  animation-delay: .5s;

  @media screen and (max-width: 800px) {
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 100vw;
    text-align: center;
  }
`;

const HeroDescription = styled.div`
  max-width: 45vw;
  font-size: 0.95rem;
  line-height: 2rem;

  ${FadeInBottom}
  animation-delay: 1s;

  @media screen and (max-width: 800px) {
    line-height: 1.5rem;
    font-size: 0.9rem;
    max-width: inherit;
    text-align: center;
  }
`;

const CallToActionContainer = styled.div`
  max-width: 45vw;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 2rem;

  & > :not(:last-child) {
    margin-right: 4rem;
  }

  @media screen and (max-width: 800px) {
    max-width: inherit;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 1rem;
    & > :not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 3rem;
    }
  }
`;

const PrimaryCallToAction = styled.a`
  font-size: 0.8rem;
  font-weight: bolder;
  background-color: ${Theme.colors.secondary};
  padding: 0.9rem 1.7rem;
  border-radius: 2rem;
  color: ${Theme.colors.primary};
  text-decoration: none;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
  text-transform: uppercase;
  flex-shrink: 0;
  flex-grow: 1;
  text-align: center;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${darken(0.1, Theme.colors.secondary)};
    }
  }

  ${FadeInBottom}
  animation-delay: 1.25s;
  @media screen and (max-height: 700px) {
    animation-delay: 1s;
  }
`;

const SecondaryCallToAction = styled(PrimaryCallToAction)`
  background-color: transparent;
  border: 1px solid ${Theme.colors.dark};
  color: ${Theme.colors.dark};

  &:hover {
    background-color: ${Theme.colors.dark};
    color: ${Theme.colors.primary};
  }

  ${FadeInBottom}
  animation-delay: 1.5s;
  @media screen and (max-height: 700px) {
    animation-delay: 1.25s;
  }
`;
