import React, { useState } from "react";
import styled from "styled-components";
import Theme from "../Theme";
import { Navigate } from "react-router-dom";

function Imprint() {
  const [toHome, setToHome] = useState<boolean>(false);

  return (
    <Container>
      <BackButton onClick={(e) => setToHome(true)}>&larr; Zurück</BackButton>
      {toHome && <Navigate to="/" />}
      <H1>Impressum</H1>
      <H2>Angaben gemäß § 5 TMG</H2>
      <H3>Vor- und Nachname</H3>
      <P> Ella Fibicher</P>
      <H3>Postanschrift</H3>
      <P>
        Kleiststr. 18
        <br />
        47546 und Kalkar
        <br />
      </P>
      <H3>Kontakt</H3>
      <P>
        Telefon: 0178 6114500
        <br />
        E-Mail: Ella_Fibicher@web.de
      </P>
      <P></P>
      <H3>Umsatzsteuer-Identifikationsnummer</H3>
      <P>116/5045/3652</P>
      <H2>Information gemäß § 36 VSBG</H2>
      <P>
        Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die
        alternative Streitbeilegung in Verbrauchersachen) erklärt der Betreiber
        dieser Website:
      </P>
      <P>
        Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </P>
    </Container>
  );
}

export default Imprint;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6rem 3rem;
  background-color: ${Theme.colors.primary};

  @media screen and (max-width: 450px) {
    padding: 6rem 2rem;
  }
`;

const H1 = styled.h1`
  font-size: 2rem;
  padding: 4rem 0 1rem 0;
  color: ${Theme.colors.dark};

  @media screen and (max-width: 450px) {
    font-size: 1.8rem;
    padding: 3.6rem 0 1rem 0;
  }
`;

const H2 = styled.h2`
  font-size: 1.5rem;
  padding: 3rem 0 1rem 0;
  color: ${Theme.colors.dark};

  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
    padding: 2.4rem 0 1rem 0;
  }
`;

const H3 = styled.h3`
  font-weight: bold;
  padding: 1.5rem 0 1rem 0;
  color: ${Theme.colors.dark};
`;

const P = styled.p`
  font-size: 0.85rem;
  line-height: 2rem;
  color: ${Theme.colors.dark};
`;

const BackButton = styled.a`
  font-size: 1rem;
  padding: 2rem 0 0rem 0;
  color: ${Theme.colors.dark};
  text-decoration: none;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
  text-transform: uppercase;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: lighten(${Theme.colors.secondary}, 10%);
    }
  }
`;
