import React from "react";

function IconFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.773"
      height="40"
      viewBox="0 0 20.773 40"
      {...props}
    >
      <g id="facebook-logo" transform="translate(-23.127)" opacity="0.898">
        <path
          id="Pfad_197"
          data-name="Pfad 197"
          d="M43.9,6.64l-3.765,0c-2.952,0-3.524,1.4-3.524,3.462v4.54h7.041l0,7.111H36.611V40H29.267V21.754h-6.14V14.643h6.14V9.4c0-6.086,3.717-9.4,9.146-9.4L43.9.009V6.64Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default IconFacebook;
