import React from "react";
import IconSew from "./svg/IconSew";
import IconEmbroider from "./svg/IconEmbroider";
import IconPresent from "./svg/IconPresent";
import styled from "styled-components";
import Theme from "../Theme";

function Services() {
  return (
    <ServiceSection>
      <Header>Meine Leistungen</Header>
      <Container>
        <Service>
          <StyledIconSew />
          <ServiceHeader>Nähen</ServiceHeader>
          <Description>
            Handgemachtes und kreatives Nähen ist das schönste Hobby der Welt.
            Tauchen Sie in meine näh-tastische Welt ein und lassen Sie sich
            inspirieren.
          </Description>
        </Service>
        <Service>
          <StyledIconEmbroider />
          <ServiceHeader>Sticken</ServiceHeader>
          <Description>
            Meine Stickereien werden Sie faszinieren und zaubern Ihnen und Ihren
            Nächsten ein Lächeln auf die Lippen. Ich besticke jeglichen Inhalt
            speziell nach Ihrem Wunsch.
          </Description>
        </Service>
        <Service>
          <StyledIconPresent />
          <ServiceHeader>Geschenkideen</ServiceHeader>
          <Description>
            Ich präsentiere Ihnen Ideen, die Sie begeistern werden. Ein
            perfektes Geschenk für Ihren individuellen Anlass wird eine
            unvergessliche Freude bereiten.
          </Description>
        </Service>
      </Container>
    </ServiceSection>
  );
}

export default Services;

const ServiceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0rem 4rem;
  @media screen and (max-width: 800px) {
    padding: 6rem 2rem 6rem 2rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 6rem;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    & > :not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 4rem;
    }
  }
`;

const Service = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`;

const Description = styled.div`
  color: ${Theme.colors.dark};
  text-align: center;
  font-size: 0.85rem;
  line-height: 2rem;
`;

const Header = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 6rem;
  text-align: center;
  @media screen and (max-width: 800px) {
    padding-bottom: 4rem;
    font-size: 1.5rem;
  }
`;

const ServiceHeader = styled.h3`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: bolder;
`;

const StyledIconSew = styled(IconSew)`
  height: 4rem;
  display: block;
  margin: 0rem auto 3rem auto;
  color: ${Theme.colors.dark};
`;

const StyledIconEmbroider = styled(IconEmbroider)`
  height: 4rem;
  display: block;
  margin: 0rem auto 3rem auto;
  color: ${Theme.colors.dark};
`;

const StyledIconPresent = styled(IconPresent)`
  height: 4rem;
  display: block;
  margin: 0rem auto 3rem auto;
  color: ${Theme.colors.dark};
`;
