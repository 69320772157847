const Theme = {
  colors: {
    primary: "#EBFFFD",
    secondary: "#4f45e7",
    tertiary: "#F9EFFE",
    dark: "rgba(5, 0, 1, 0.9)",
    light: "rgba(245, 255, 254, 0.9)",
  },
};

export default Theme;
