import React, { useState } from "react";
import styled from "styled-components";
import Theme from "../Theme";
import { Navigate } from "react-router-dom";

declare global {
  const gaOptout: () => void;
}

function DataProtection() {
  const [toHome, setToHome] = useState<boolean>(false);
  return (
    <Container>
      <BackButton onClick={(e) => setToHome(true)}>&larr; Zurück</BackButton>
      {toHome && <Navigate to="/" />}
      <H1>Datenschutzerklärung</H1>
      <P>
        Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
        EU-Datenschutzgrundverordnung (DSGVO), ist:
      </P>
      <P>
        Ella Fibicher
        <br />
        Kleiststr. 18
        <br />
        47546 Kalkar
      </P>
      <H2>Ihre Betroffenenrechte</H2>
      <P>
        Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
        können Sie jederzeit folgende Rechte ausüben:
      </P>
      <Ul>
        <Li>
          Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
          (Art. 15 DSGVO),
        </Li>
        <Li>
          Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
        </Li>
        <Li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</Li>
        <Li>
          Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
          gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
        </Li>
        <Li>
          Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
          und
        </Li>
        <Li>
          Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
          haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
        </Li>
      </Ul>
      <P>
        Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
        jederzeit mit Wirkung für die Zukunft widerrufen.
      </P>
      <P>
        Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
        wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres
        Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
        Behörde.
      </P>
      <P>
        Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
        Anschrift finden Sie unter:{" "}
        <A
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
          target="_blank"
          rel="nofollow noopener"
        >
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </A>
        .
      </P>
      <P></P>
      <H2>Kontaktformular</H2>
      <H3>Art und Zweck der Verarbeitung:</H3>
      <P>
        Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
        Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer
        validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der
        Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die
        Angabe weiterer Daten ist optional.
      </P>
      <H3>Rechtsgrundlage:</H3>
      <P>
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
        auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f
        DSGVO).
      </P>
      <P>
        Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine
        unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben
        werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
        Anschlussfragen gespeichert.
      </P>
      <P>
        Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen,
        erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten
        zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b
        DSGVO).
      </P>
      <H3>Empfänger:</H3>
      <P>Empfänger der Daten sind ggf. Auftragsverarbeiter.</P>
      <H3>Speicherdauer:</H3>
      <P>
        Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.
      </P>
      <P>
        Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den
        gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach
        Ablauf dieser Fristen.{" "}
      </P>
      <H3>Bereitstellung vorgeschrieben oder erforderlich:</H3>
      <P>
        Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir
        können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen,
        Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
      </P>
      <P></P>
      <H2>Verwendung von Google Analytics</H2>
      <H3>Art und Zweck der Verarbeitung:</H3>
      <P>
        Diese Website benutzt Google Analytics, einen Webanalysedienst der
        Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA
        (nachfolgend: „Google“). Google Analytics verwendet sog. „Cookies“, also
        Textdateien, die auf Ihrem Computer gespeichert werden und die eine
        Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das
        Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite
        werden in der Regel an einen Server von Google in den USA übertragen und
        dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf
        diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von
        Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
        des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur
        in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
        in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
        Website wird Google diese Informationen benutzen, um Ihre Nutzung der
        Webseite auszuwerten, um Reports über die Webseitenaktivitäten
        zusammenzustellen und um weitere mit der Websitenutzung und der
        Internetnutzung verbundene Dienstleistungen gegenüber dem
        Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von
        Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
        Google zusammengeführt.
      </P>
      <P>
        Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung
        der Website und in der Zusammenstellung von Reports über Aktivitäten auf
        der Website. Auf Grundlage der Nutzung der Website und des Internets
        sollen dann weitere verbundene Dienstleistungen erbracht werden.
      </P>
      <H3>Rechtsgrundlage:</H3>
      <P>
        Die Verarbeitung der Daten erfolgt auf Grundlage einer Einwilligung des
        Nutzers (Art. 6 Abs. 1 lit. a DSGVO).
      </P>
      <H3>Empfänger:</H3>
      <P>
        Empfänger der Daten ist Google als Auftragsverarbeiter. Hierfür haben
        wir mit Google den entsprechenden Auftragsverarbeitungsvertrag
        abgeschlossen.
      </P>
      <H3>Speicherdauer:</H3>
      <P>
        Die Löschung der Daten erfolgt, sobald diese für unsere
        Aufzeichnungszwecke nicht mehr erforderlich sind.
      </P>
      <H3>Drittlandtransfer:</H3>
      <P>
        Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy
        Shield unterworfen{" "}
        <A href="https://www.privacyshield.gov/EU-US-Framework">
          https://www.privacyshield.gov/EU-US-Framework
        </A>
        .{" "}
      </P>
      <H3>Bereitstellung vorgeschrieben oder erforderlich:</H3>
      <P>
        Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
        allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden,
        kann es hierdurch zu Funktionseinschränkungen auf der Website kommen.
      </P>
      <H3>Widerruf der Einwilligung:</H3>
      <P>
        Sie können die Speicherung der Cookies durch eine entsprechende
        Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
        darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
        Funktionen dieser Website vollumfänglich werden nutzen können. Sie
        können darüber hinaus die Erfassung der durch das Cookie erzeugten und
        auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse)
        an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
        indem sie das unter dem folgenden Link verfügbare Browser-Plugin
        herunterladen und installieren:{" "}
        <A href="http://tools.google.com/dlpage/gaoptout?hl=de">
          Browser Add On zur Deaktivierung von Google Analytics
        </A>
        .
      </P>
      <P>
        Zusätzlich oder als Alternative zum Browser-Add-On können Sie das
        Tracking durch Google Analytics auf unseren Seiten unterbinden, indem
        Sie{" "}
        <A
          title="Google Analytics Opt-Out-Cookie setzen"
          onClick={(e) => {
            if (gaOptout) {
              gaOptout();
              alert("Google Analytics wurde deaktiviert");
            }
          }}
          href="#"
        >
          diesen Link anklicken
        </A>
        . Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird
        die Erfassung durch Google Analytics für diese Website und für diesen
        Browser zukünftig verhindert, so lange das Cookie in Ihrem Browser
        installiert bleibt.
      </P>
      <H3>Profiling:</H3>
      <P>
        Mit Hilfe des Tracking-Tools Google Analytics kann das Verhalten der
        Besucher der Webseite bewertet und die Interessen analysiert werden.
        Hierzu erstellen wir ein pseudonymes Nutzerprofil.
      </P>
      <P></P>
      <H2>Verwendung von Scriptbibliotheken (Google Webfonts)</H2>
      <H3>Art und Zweck der Verarbeitung:</H3>
      <P>
        Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
        darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der
        Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
        nachfolgend „Google“) zur Darstellung von Schriften.
      </P>
      <P>
        Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie
        hier:{" "}
        <A href="https://www.google.com/policies/privacy/">
          https://www.google.com/policies/privacy/
        </A>
      </P>
      <H3>Rechtsgrundlage:</H3>
      <P>
        Rechtsgrundlage für die Einbindung von Google Webfonts und dem damit
        verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1
        lit. a DSGVO).{" "}
      </P>
      <H3>Empfänger:</H3>
      <P>
        Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst
        automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist
        es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu
        welchen Zwecken – dass der Betreiber in diesem Fall Google Daten erhebt.
      </P>
      <H3>Speicherdauer:</H3>
      <P>
        Wir erheben keine personenbezogenen Daten, durch die Einbindung von
        Google Webfonts.
      </P>
      <P>
        Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
        <A href="https://developers.google.com/fonts/faq">
          https://developers.google.com/fonts/faq
        </A>{" "}
        und in der Datenschutzerklärung von Google:{" "}
        <A href="https://www.google.com/policies/privacy/">
          https://www.google.com/policies/privacy/
        </A>
        .
      </P>
      <H3>Drittlandtransfer:</H3>
      <P>
        Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy
        Shield unterworfen{" "}
        <A href="https://www.privacyshield.gov/EU-US-Framework">
          https://www.privacyshield.gov/EU-US-Framework
        </A>
        .
      </P>
      <H3>Bereitstellung vorgeschrieben oder erforderlich:</H3>
      <P>
        Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich,
        noch vertraglich vorgeschrieben. Allerdings kann ggfs. die korrekte
        Darstellung der Inhalte durch Standardschriften nicht möglich sein.
      </P>
      <H3>Widerruf der Einwilligung:</H3>
      <P>
        Zur Darstellung der Inhalte wird regelmäßig die Programmiersprache
        JavaScript verwendet. Sie können der Datenverarbeitung daher
        widersprechen, indem Sie die Ausführung von JavaScript in Ihrem Browser
        deaktivieren oder einen JavaScript-Blocker installieren. Bitte beachten
        Sie, dass es hierdurch zu Funktionseinschränkungen auf der Website
        kommen kann.
      </P>
      <P></P>
      <H2>SSL-Verschlüsselung</H2>
      <P>
        Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden
        wir dem aktuellen Stand der Technik entsprechende
        Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
      </P>
      <P></P>
      <H2>Änderung unserer Datenschutzbestimmungen</H2>
      <P>
        Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
        stets den aktuellen rechtlichen Anforderungen entspricht oder um
        Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
        z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
        dann die neue Datenschutzerklärung.
      </P>
    </Container>
  );
}

export default DataProtection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6rem 3rem;
  background-color: ${Theme.colors.primary};

  @media screen and (max-width: 450px) {
    padding: 6rem 2rem;
  }
`;

const H1 = styled.h1`
  font-size: 2rem;
  padding: 4rem 0 1rem 0;
  color: ${Theme.colors.dark};

  @media screen and (max-width: 450px) {
    font-size: 1.8rem;
    padding: 3.6rem 0 1rem 0;
  }
`;

const H2 = styled.h2`
  font-size: 1.5rem;
  padding: 3rem 0 1rem 0;
  color: ${Theme.colors.dark};

  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
    padding: 2.4rem 0 1rem 0;
  }
`;

const H3 = styled.h3`
  font-weight: bold;
  padding: 1.5rem 0 1rem 0;
  color: ${Theme.colors.dark};
`;

const A = styled.a`
  font-size: 0.85rem;
  color: ${Theme.colors.dark};
  word-break: break-all;
  word-wrap: break-word;
  cursor: pointer;
`;

const Ul = styled.ul`
  box-sizing: border-box;
  list-style-position: inside;
  padding: 1rem 0;
  color: ${Theme.colors.dark};
`;

const Li = styled.li`
  font-size: 0.85rem;
  line-height: 2rem;
  :not(:last-child) {
    padding-bottom: 1rem;
  }
`;

const P = styled.p`
  font-size: 0.85rem;
  line-height: 2rem;
  color: ${Theme.colors.dark};
`;

const BackButton = styled.a`
  font-size: 1rem;
  padding: 2rem 0 0rem 0;
  color: ${Theme.colors.dark};
  text-decoration: none;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
  text-transform: uppercase;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: lighten(${Theme.colors.secondary}, 10%);
    }
  }
`;
